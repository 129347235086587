@layer base {
    :root {
        --green: #33f20d;
        --whiteText: #fff;

        --sans: "Open Sans", sans-serif;

        /* Font Scale */
        --base-size: 1rem;
        --scale: 1.25;
        --h1: calc(var(--h2) * var(--scale));
        /* 3.66rem */
        --h2: calc(var(--h3) * var(--scale));
        /* 2.93rem */
        --h3: calc(var(--h4) * var(--scale));
        /* 2.34rem */
        --h4: calc(var(--h5) * var(--scale));
        /* 1.875rem */
        --h5: calc(var(--h6) * var(--scale));
        /* 1.5rem */
        --h6: var(--base-size);
        /* 1.2rem */
        --small: calc(var(--base-size) / var(--scale));
        /* 0.96rem */
        --radius: 5px;
    }

    html {
        box-sizing: border-box;
    }

    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }

    body {
        font-family: var(--sans);
        line-height: 1.5;
        font-size: var(--base-size);
        padding: 0;
        margin: 0;
        color: var(--whiteText);
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 400;
        margin: 0;
    }

    .container {
        padding: 0.2rem;
    }

    header {
        border-bottom: 2px solid white;
        padding: 0 10px 10px 10px;
    }

    .titles-box {
        display: flex;
        align-items: center;
    }

    .titleBar {
        font-size: var(--h3);
    }

    .subTitleBar {
        font-size: var(--h5);
    }

    .scrollBox {
        margin: 30px 0 0 0;
    }

    .downIcon {
        position: absolute;
        bottom: 10%;
        left: 45%;
    }

}

@media only screen and (max-width: 768px) {

    header {
        position: sticky;
        top: 0%;
        grid-column: 1 / span 12;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .aboutPara {
        grid-column: 1/12;
    }

    .titles-box {
        display: inline-block
    }

    .titleBar {
        font-size: var(--h5);
        margin-right: 0.6rem;
    }

    .subTitleBar {
        font-size: var(--h6);
    }

    .appScreen h2 {
        text-align: center;
        color: var(--green);
    }

    .appButtons {
        text-align: center;
    }
}

@media (min-width: 810px) {

    header {
        display: flex;
        justify-content: space-between;
    }

    .titles-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .subTitleBar {
        padding-left: 20px;
    }

    .aboutPara {
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .appButtons {
        text-align: center;
    }

    .appScreen h2 {
        text-align: center;
        color: var(--green);
    }
}

@media (min-width: 1024px) {
    header {
        display: flex;
        justify-content: space-between;
    }

    .aboutPara {
        margin-left: 25rem;
        margin-right: 25rem;
        text-align: left;
    }
}